.session-filter-header select{
    width: 10rem;
}

.session-editor-quantity-container{
    gap: 1rem;
}

.session-editor-quantity-container input{
    width: 50px;
    text-align: center;
    /* padding-top: 0.25rem;
    padding-bottom: 0.25rem;
    padding-left: 0.625rem;
    padding-right: 0.625rem; */
}