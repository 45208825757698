@tailwind base;
@tailwind components;
@tailwind utilities;

body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

.hk-gradient-bg{
  background-image: linear-gradient(282.87deg,#d32efe 14.09%,#05fffa 103.26%);
}

.dashboard-session-table-wrapper{
  filter: drop-shadow(0px 2px 15px rgba(0, 0, 0, 0.05));
  border-radius: 10px;
}